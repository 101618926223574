import { defineStore } from 'pinia'
export const useGaTrackingStore = defineStore('gaTracking', () => {
  const pageTrackingData = reactive({
    content_group: null,
    content_type: null,
    content_name: null,
    content_id: null
  })

  return { pageTrackingData }
})
